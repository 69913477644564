import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ISection } from '@/interfaces/index';
import { Typography, WrapperExternal } from '@/components/atoms';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { isUsUserFn } from '@/utils/index';

const SectionContainer = styled.section<ISection>`
  margin-bottom: clamp(2.5rem, 1.8627rem + 2.6144vw, 5rem); //40px - 80px
  ${props =>
    props.noHeight &&
    css`
      height: 0px;
    `}

  ${props =>
    props.marginBottom === 'small' &&
    css`
      margin-bottom: clamp(1.5rem, 1.2451rem + 1.0458vw, 2.5rem); //24 - 40
    `}
  ${props =>
    props.marginBottom === 'none' &&
    css`
      margin-bottom: 0px !important;
    `}
`;

const Heading = styled.div`
  text-align: left;
  margin-bottom: 24px;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-bottom: 32px;
  }
`;

const Section: React.FC<ISection> = props => {
  const [isMounted, setIsMounted] = useState(!props.visibility ? true : false);
  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const loadingCustomer = useSelector(
    (state: RootState) => state.userReducer.loadingCustomer
  );

  const customer = useSelector(
    (state: RootState) => state.userReducer.customer
  );

  const isUsUser = isUsUserFn(customer);

  useEffect(() => {
    if (!loadingCustomer) {
      if (
        (props.visibility === 'anonimous' && !isLogged) ||
        (props.visibility === 'logged_in' && isLogged) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForCustomerWithPreferences === 'with_preferences' &&
          customer.registrationPreferences) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForCustomerWithPreferences ===
            'without_preferences' &&
          !customer.registrationPreferences) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForUsCustomer &&
          isUsUser) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForUsCustomer === undefined)
      ) {
        setIsMounted(true);
      }
      if (
        (props.visibility === 'anonimous' && isLogged) ||
        (props.visibility === 'logged_in' && !isLogged) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForCustomerWithPreferences === 'with_preferences' &&
          !customer.registrationPreferences) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForCustomerWithPreferences ===
            'without_preferences' &&
          customer.registrationPreferences) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForUsCustomer &&
          !isUsUser) ||
        (props.visibility === 'logged_in' &&
          isLogged &&
          props.visibilityForUsCustomer !== undefined &&
          !props.visibilityForUsCustomer &&
          isUsUser)
      ) {
        setIsMounted(false);
      }
    }
  }, [isLogged, loadingCustomer, customer, isUsUser]);

  return props.children && isMounted ? (
    <SectionContainer
      marginBottom={props.marginBottom}
      noHeight={props.noHeight}>
      {props.heading ? (
        <WrapperExternal>
          <Heading>
            <Typography component="h2" datatype={'heading_5'}>
              {props.heading}
            </Typography>
          </Heading>
        </WrapperExternal>
      ) : null}
      {props.children}
    </SectionContainer>
  ) : null;
};

export default Section;
