import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ISection, IParagraph } from '@/types/contentful/contentful';
import { IContent } from '@/types/index';
const useAbTest = (content: IContent) => {
  const [appProps, setAppProps] = useState(content);
  const router = useRouter();

  const abtest = (e: any) => {
    let experiments: any = {};
    experiments[e.detail.experimentID] = e.detail.variant;
    window.Mirta = window.Mirta || {};
    window.Mirta.experiments = {
      ...window.Mirta.experiments,
      ...experiments,
    };
    setAbTestContent();
  };

  const setAbTestContent = () => {
    if (window && window.Mirta && window.Mirta.experiments) {
      let newContent: (IParagraph | ISection)[] = [];
      let experiments =
        window.Mirta && window.Mirta.experiments
          ? window.Mirta.experiments
          : {};
      if (content) {
        content.forEach(el => {
          if (
            el?.fields?.experimentId &&
            Object.keys(experiments).indexOf(el.fields.experimentId) > -1 &&
            experiments[el.fields.experimentId] > 0
          ) {
            if (el?.fields?.variants) {
              newContent.push(
                el.fields.variants[experiments[el.fields.experimentId] - 1]
              );
            }
          } else {
            newContent.push(el);
          }
        });
      }
      setAppProps(newContent);
    } else {
      setAppProps(content);
    }
  };

  useEffect(() => {
    document.addEventListener('abtest', abtest);
    if (window?.gtag && window?.implementManyExperiments) {
      window.Mirta.pushEventToAnalytics('optimize.callback', {
        callback: window.implementManyExperiments,
      });
    }

    return () => {
      document.removeEventListener('abtest', abtest);
    };
  }, []);

  useEffect(() => {
    setAbTestContent();
  }, [router]);

  return appProps;
};
export default useAbTest;
