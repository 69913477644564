import Page from '@/components/templates/Page';
import { getEntry } from '@/lib/api.cms';
import Layout from '@/components/layout';
import { META_ROBOTS_DEFAULT } from '@/lib/constants';
import { getLocales } from '@/lib/api.locale';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { IPage, IData } from '@/types/index';
import { GetStaticProps } from 'next';
import { getSettings } from '@/utils/Settings';
import { getSettingsTheme } from '@/utils/SettingsTheme';
import { getProductCarousel, IProdCarousel } from '@/utils/Product';
import { IPage as IPageContentful } from '@/types/contentful/contentful';
import { IMirtaServerProps } from '@/types/IMirtaServerProps';
import { DEFAULT_CURRENCY } from '@/utils/Currency';

const HomePage: React.FC<IPage> = props => {
  const { preview, data, locales, locale, pageType, currency } = props;

  return (
    <Layout
      layout={data.layout}
      preview={preview}
      locales={locales}
      locale={locale}
      pageType={pageType}
      currency={currency}>
      <Page content={data.content} extraContent={data.extraContent} />
    </Layout>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps<
  IMirtaServerProps
> = async context => {
  const { locale = 'en' } = context;
  const preview = context.preview ? context.preview : false;
  const locales = getLocales();
  const currency = DEFAULT_CURRENCY;
  const page = (await getEntry(
    'page',
    'slug',
    'index',
    locale,
    preview
  )) as IPageContentful;

  if (!page || !page.fields) {
    return {
      notFound: true,
    };
  }

  let fieldsContent = page?.fields?.content || [];

  fieldsContent = fieldsContent.map((f, i) => {
    return {
      ...f,
      mode: 'content',
      lastOne: i === fieldsContent.length - 1,
    };
  });

  let productCarousel: IProdCarousel[] = [];
  productCarousel = await getProductCarousel(fieldsContent);

  const canonicalUrl = {
    canonical: `${process.env.BASE_DOMAIN_URL}/${
      locale !== context.defaultLocale ? locale + '/' : ''
    }`,
  };

  const robots = {
    robots:
      page.fields.meta &&
      page.fields.meta.fields &&
      page.fields.meta.fields.robots
        ? page.fields.meta.fields.robots
        : META_ROBOTS_DEFAULT,
  };

  let data: IData = {
    layout: {
      header: page.fields.layout?.fields?.header?.fields || null,
      footer: page.fields.layout?.fields?.footer?.fields || null,
      meta: { ...page.fields.meta?.fields, ...canonicalUrl, ...robots },
    },
    content: [
      ...(fieldsContent || []),
      ...(page.fields.layout?.fields?.footer?.fields.section || []),
    ],

    extraContent: {
      productCarousel,
    },
  };

  const settings = getSettings(data);
  const settingsTheme = getSettingsTheme();

  return {
    props: {
      data,
      preview,
      locales: locales.items,
      locale,
      pageType: 'homepage',
      settings,
      settingsTheme,
      currency,
      ...(await serverSideTranslations(locale, [
        'product',
        'common',
        'account',
      ])),
    },
    revalidate: 60, // In seconds
  };
};
