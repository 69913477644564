import React from 'react';
import { Section, WrapperExternal } from '@/components/atoms';
import createComponents, { IRest } from '@/utils/createComponents';
import useAbTest from '@/hooks/useAbTest';
import { IPageCmp } from './IPageCmp';
import { isSection, isParagraph } from '@/utils/TypeGuards';
import {
  IImageWithTextBlockFields,
  IProductCarouselFields,
} from '@/types/contentful/contentful';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useAppContext } from '@/context/state';

const Page: React.FC<IPageCmp> = ({ content, extraContent, pageType }) => {
  let cont: (JSX.Element | null | undefined)[] = [];

  const wishlistData = useSelector(
    (state: RootState) => state.wishlistReducer.wishlist
  );
  const cartHandles = useSelector(
    (state: RootState) => state.cartReducer.cartHandles
  );
  const context = useAppContext();

  const minProductsWishlistCarousel =
    context?.settings?.minProductsWishlistCarousel || 0;

  const showWishlistSection =
    wishlistData &&
    wishlistData.length > 0 &&
    wishlistData.length >= minProductsWishlistCarousel;

  const minProductsCartCarousel =
    context?.settings?.minProductsCartCarousel || 0;

  const showCartSection =
    cartHandles &&
    cartHandles.length > 0 &&
    cartHandles.length >= minProductsCartCarousel;

  const appProps = useAbTest(content);

  if (appProps && appProps.length > 0) {
    cont = appProps.map((block, index: number) => {
      let marginBottom: string | undefined = '';
      let withNoPaddingRight: boolean = false;
      let showSection = true;
      // PARAGRAPH ANDREBBE MESSO DENTRO SECTION IN CONTENTFUL //
      if (isParagraph(block)) {
        marginBottom = block.fields.marginBottom;

        return (
          <Section
            key={index + block.sys.contentType.sys.id}
            marginBottom={marginBottom}
            visibility={block.fields?.visibility}
            visibilityForCustomerWithPreferences={
              block.fields?.visibilityForCustomerWithPreferences
            }
            visibilityForUsCustomer={block.fields?.visibilityForUsCustomer}>
            <WrapperExternal>
              {createComponents(block, { pageType, index })}
            </WrapperExternal>
          </Section>
        );
      }

      if (isSection(block)) {
        marginBottom = block.fields.marginBottom;
        let rest: IRest = {};
        const bgcolor = block.fields.backgroundColor || null;
        const template = block.fields.template || null;
        const paddingBottom = block.fields.paddingBottom || null;

        if (
          block.fields.organism?.sys?.contentType?.sys?.id === 'productCarousel'
        ) {
          withNoPaddingRight = true;
          rest = {
            id: block.fields.organism.sys?.id,
            index: index,
            bgcolor: block.fields.backgroundColor || null,
          };
          if (
            extraContent &&
            extraContent.productCarousel &&
            extraContent.productCarousel.length > 0
          ) {
            extraContent.productCarousel.forEach(prd => {
              if (prd.id === block.fields?.organism?.sys?.id) {
                rest = {
                  ...rest,
                  products: prd.data,
                };
              }
            });
          }

          if (
            (block.fields.organism.fields as IProductCarouselFields).type ===
              'wishlist' &&
            !showWishlistSection
          ) {
            showSection = false;
          }
          if (
            (block.fields.organism.fields as IProductCarouselFields).type ===
              'cart' &&
            !showCartSection
          ) {
            showSection = false;
          }
        } else if (
          block.fields.organism?.sys?.contentType?.sys?.id === 'popupTimer'
        ) {
          marginBottom = 'none';
          rest = {
            index: index,
            bgcolor: block.fields.backgroundColor || null,
            visibility: block.fields.visibility || null,
          };
        } else if (
          block.fields.organism?.sys?.contentType?.sys?.id ===
          'imageWithTextBlock'
        ) {
          if (
            (block.fields.organism?.fields as IImageWithTextBlockFields)
              .template === 'carousel_custom_small' ||
            (block.fields.organism?.fields as IImageWithTextBlockFields)
              .template === 'carousel_custom'
          ) {
            withNoPaddingRight = true;
          }
        } else {
          rest = {
            index: index,
            bgcolor: block.fields.backgroundColor || null,
          };
        }

        return showSection ? (
          <Section
            key={index + (block.fields.organism?.sys.contentType.sys.id || '')}
            marginBottom={marginBottom}
            heading={block.fields.heading}
            visibility={block.fields?.visibility}
            visibilityForCustomerWithPreferences={
              block.fields?.visibilityForCustomerWithPreferences
            }
            visibilityForUsCustomer={block.fields?.visibilityForUsCustomer}>
            <WrapperExternal
              bgcolor={bgcolor}
              template={template}
              paddingBottom={paddingBottom}
              withNoPaddingRight={withNoPaddingRight}>
              {createComponents(block.fields.organism, rest)}
            </WrapperExternal>
          </Section>
        ) : null;
      }
      return createComponents(block);
    });
  }

  return <>{cont}</>;
};

export default Page;
