import {
  IParagraph,
  ISection,
  IImageWithTextBlock,
} from '@/types/contentful/contentful';
import { Block, Inline, Text } from '@contentful/rich-text-types';

export function isSection(block: IParagraph | ISection): block is ISection {
  return (block as ISection).sys?.contentType?.sys?.id === 'section';
}

export function isParagraph(block: IParagraph | ISection): block is IParagraph {
  return (block as IParagraph).sys?.contentType?.sys?.id === 'paragraph';
}

export function isText(object: Block | Inline | Text): object is Text {
  return (object as Text).value !== undefined;
}

export function isImageWithTextBlock(
  object: IImageWithTextBlock
): object is IImageWithTextBlock {
  return (
    (object as IImageWithTextBlock).sys?.contentType?.sys?.id ===
    'imageWithTextBlock'
  );
}
